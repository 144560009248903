import React from "react";
import Img from "gatsby-image";
import PropTypes from "prop-types";

const Image = props => {
  const { altText, image } = props;

  // Image may have `null` for its hotspot value, hence we cannot reliably set
  // a default during destructuring and must do so here.
  const hotspot = image.hotspot || {};

  // Default x and y hotspot values to the center of the asset if they were not
  // passed in with the project itself.
  const {
    x=0.5,
    y=0.5
  } = hotspot;

  const fluid = image.asset.fluid;

  return (
    <Img
      alt={altText}
      fadeIn={false}
      fluid={fluid}
      loading="eager"
      objectFit="cover"
      objectPosition={`${ x * 100 }% ${ y * 100 }%`}
      style={
        {
          height: "100%",
          position: "relative",
          width: "100%",
          willChange: "transform"
        }
      }
      placeholderStyle={
        {
          filter: "blur(20px)"
        }
      }
    />
  );
};

Image.propTypes = {
  altText: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired
};

export default Image;
