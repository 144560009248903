import React, { useMemo } from "react";

import Slide from "./components/Slide";

const Slides = props => {
  const { selectedIndex, slides } = props;

  return useMemo(() => {
    return (
      <React.Fragment>
        {
          slides.map((slide, i) => {
            const selected = selectedIndex === i;

            return (
              <Slide
                key={`home__carousel-slide-k-${ i }`}
                selected={selected}
                slide={slide}
              />
            );
          })
        }
      </React.Fragment>
    );
  }, [selectedIndex, slides]);
};

export default Slides;
