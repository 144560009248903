import classNames from "classnames";
import { Link } from "gatsby";
import styled from "styled-components";

import SlideButton from "./SlideButton";

const SlideProjectLinkButton = styled(SlideButton).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "home__carousel__slide-project-link-button"
    )
  };
})``;

SlideProjectLinkButton.propTypes = {};

SlideProjectLinkButton.defaultProps = {
  as: Link
};

export default SlideProjectLinkButton;
