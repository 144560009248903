import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import PlayerWrapper from "./views/PlayerWrapper";

const Video = props => {
  const {
    vimeoVideo
  } = props;

  const {
    oEmbedData: {
      html,
      width,
      height,
      thumbnail_url
    }
  } = vimeoVideo;

  const [didMount, setDidMount] = useState(false);

  useEffect(() => setDidMount(true), []);

  return (
    <PlayerWrapper height={height} width={width} thumbnailSrc={thumbnail_url}>
      {
        // We wait for mount to set the inner iframe html in order to prevent
        // Gatsby from "prefetching" vimeo embeds on page transitions, which
        // leads to the user having to wait for vimeo servers to respond with
        // data per video during any route change.
        didMount && (
          <div dangerouslySetInnerHTML={{ __html: html }}/>
        )
      }
    </PlayerWrapper>
  );
};

Video.propTypes = {
  vimeoVideo: PropTypes.object.isRequired
};

export default Video;
