import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useEmblaCarousel } from "embla-carousel/react";
import { setupWheelGestures } from "embla-carousel-wheel-gestures";

import NextSlideButton from "./components/NextSlideButton";
import NextSlideButtonPseudoButton from
  "./components/NextSlideButtonPseudoButton";
import Slides from "./components/Slides";
import StyledCarousel from "./views/Carousel";
import CarouselInnerWrapper from "./views/CarouselInnerWrapper";
import CarouselOuterWrapper from "./views/CarouselOuterWrapper";

const Carousel = props => {
  const { slides } = props;

  const [emblaRef, embla] = useEmblaCarousel({
    align: "start",
    draggableClass: "",
    draggingClass: "",
    selectedClass: "",
    loop: true,
    slidesToScroll: 1
  });

  const [
    displayNextSlideButtonPseudoButton,
    setDisplayNextSlideButtonPseudoButton
  ] = useState(false);
  const [settled, setSettled] = useState(true);
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(0);
  const [
    nextSlideButtonPseudoButtonTrigger,
    setNextSlideButtonPseudoButtonTrigger
  ] = useState(null);

  useEffect(() => embla && setupWheelGestures(embla), [embla]);

  useEffect(() => {
    if (!embla) { return; }

    const handleScroll = () => {
      setSettled(false);
    };

    const handleSelect = () => {
      setSelectedSlideIndex(embla.selectedScrollSnap());
    };

    const handleSettle = () => {
      setSettled(true);
    };

    handleSelect();

    embla.on("scroll", handleScroll);
    embla.on("select", handleSelect);
    embla.on("settle", handleSettle);
  }, [embla]);

  useEffect(() => {
    if (!nextSlideButtonPseudoButtonTrigger) { return; }

    const hideNextSlideButtonPseudoButton = () => {
      setDisplayNextSlideButtonPseudoButton(false);
    };

    setDisplayNextSlideButtonPseudoButton(true);
    const hideTimeout = setTimeout(hideNextSlideButtonPseudoButton, 450);

    return () => {
      clearTimeout(hideTimeout);
    }
  }, [nextSlideButtonPseudoButtonTrigger]);

  const handleGoToNextSlideClicked = () => {
    if (embla) {
      embla.scrollNext();
      setNextSlideButtonPseudoButtonTrigger(+new Date());
    }
  };

  return (
    <CarouselOuterWrapper>
      <CarouselInnerWrapper ref={emblaRef}>
        <StyledCarousel>
          <Slides
            selectedIndex={selectedSlideIndex}
            slides={slides}
          />
        </StyledCarousel>
        <NextSlideButton
          onClick={handleGoToNextSlideClicked}
          settled={settled}
        />
      </CarouselInnerWrapper>
      {
        // The pseudo button is used to overlay our slide with a click detector
        // briefly once the user has clicked the next slide button, allowing the
        // user to quickly spam click on the same area and continue to trigger
        // the "next slide" functionality without interrupting the carousel
        // animation by "grabbing" a slide in transition.
      }
      <NextSlideButtonPseudoButton
        onClick={handleGoToNextSlideClicked}
        displayed={displayNextSlideButtonPseudoButton}
      />
    </CarouselOuterWrapper>
  );
};

Carousel.PARALLAX_FACTOR = 1;//1.2;

export default Carousel;
