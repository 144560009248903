import classNames from "classnames";
import styled from "styled-components";

const PlayerWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "home__carousel__slide-video__player-wrapper"
    )
  };
})`
  ${({ height, width, thumbnailSrc }) => `
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;

    &:before {
      background-image: url(${ thumbnailSrc });
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: 0;
      content: "";
      filter: blur(12px);
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    > div {
      height: 100%;
      left: 50%;
      min-height: calc(${ (height / width) * 100 } * var(--vw));
      min-width: 100%;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(${ (width / height) * 100 } * var(--vh));

      > iframe {
        height: 100%;
        width: 100%;
      }
    }
  `}
`;

PlayerWrapper.defaultProps = {};

export default PlayerWrapper;
