import classNames from "classnames";
import styled from "styled-components";

const Slide = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "home__carousel__slide")
  };
})`
  ${() => `
    flex: 0 0 80%;
    height: 100%;
    padding-right: 10px;
    position: relative;
    transform: translateZ(0);
    user-select: none;
    width: 80%;
    will-change: transform;
  `}
`;

Slide.propTypes = {};

Slide.defaultProps = {};

export default Slide;
