import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";

const NextSlideButtonPseudoButton = styled.div.attrs(props => {
  const {
    className,
    displayed
  } = props;

  return {
    className: classNames(className, "home__carousel__next-slide-button-pseudo-button", {
      "home__carousel__next-slide-button-pseudo-button--is-displayed": displayed
    })
  };
})`
  ${() => `
    bottom: 0;
    cursor: pointer;
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 20%;
    will-change: transform;

    &.home__carousel__next-slide-button-pseudo-button--is-displayed {
      display: block;
    }
  `}
`;

NextSlideButtonPseudoButton.propTypes = {
  displayed: PropTypes.bool.isRequired
};

NextSlideButtonPseudoButton.defaultProps = {
  displayed: true
};

export default NextSlideButtonPseudoButton;
