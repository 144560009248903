import classNames from "classnames";
import styled from "styled-components";

const Carousel = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "home__carousel")
  };
})`
  ${() => `
    display: flex;
    flex: 0 0 100%;
    will-change: transform;
  `};
`;

Carousel.propTypes = {};

Carousel.defaultProps = {};

export default Carousel;
