import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { media } from "styled-bootstrap-grid";
import styled, { withTheme } from "styled-components";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

import { RightArrow } from "@nowadays/UI/Icon";

const StyledNextSlideButton = styled.div.attrs(props => {
  const {
    className,
    settled
  } = props;

  return {
    className: classNames(className, "home__carousel__next-slide-button", {
      "home__carousel__next-slide-button--is-settled": settled
    })
  };
})`
  ${({ theme: { lineHeights, space } }) => `
    align-items: flex-end;
    bottom: 0;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    // If Nowadays ever wants exclusion turned back on, find all instances of
    // this comment and uncomment the mix-blend-mode line.
    // mix-blend-mode: exclusion;
    opacity: 0.01;
    outline: none;
    padding: ${ space.small } ${ space.medium } ${ space.small } 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 40ms cubic-bezier(.455, .03, .515, 1.0);
    width: 20%;
    will-change: transform;

    &.home__carousel__next-slide-button--is-settled {
      opacity: 1;
      transition: opacity 320ms cubic-bezier(.455, .03, .515, 1.0);
    }

    svg {
      height: ${ space.xSmall };
      margin-bottom:
        calc((${ lineHeights.xLarge } / 2) - ${ space.xxSmall });
    }
  `}

  ${media.sm`
    ${ ({ theme: { lineHeights, space } }) => `
      && {
        padding: ${ space.large } ${ space.xLarge } ${ space.large } 0;

        svg {
          height: ${ space.small };
          margin-bottom:
            calc((${ lineHeights.xxLarge } / 2) - ${ space.xxSmall });
        }
      }
    `}
  `}
`;

const NextSlideButton = React.forwardRef(({ theme, ...other }, ref) => (
  <KeyboardFocusContainer>
    {({ getFocusProps, keyboardFocused }) => (
      <StyledNextSlideButton
        {...getFocusProps({
          ref,
          ...other,
          focused: keyboardFocused
        })}
      >
        <RightArrow color={theme.colors.white}/>
      </StyledNextSlideButton>
    )}
  </KeyboardFocusContainer>
));

NextSlideButton.propTypes = {
  settled: PropTypes.bool.isRequired
};

NextSlideButton.defaultProps = {
  settled: true
};

export default withTheme(NextSlideButton);
