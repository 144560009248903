import React, { useMemo } from "react";
import PropTypes from "prop-types";

import Header from "./components/Header";
import Image from "./components/Image";
import Video from "./components/Video";
import StyledSlide from "./views/Slide";
import SlideExternalLinkButton from "./views/SlideExternalLinkButton";
import SlideProjectLinkButton from "./views/SlideProjectLinkButton";

const Slide = props => {
  const { selected, slide } = props;

  const getSlideExternalButtonProps = () => {
    const buttonProps = {
      disabled: !selected,
      href: slide.link.href
    };

    if (slide.link.newTab) {
      buttonProps.target = "_blank";
      buttonProps.rel = "noopener noreferrer";
    }

    return buttonProps;
  };

  const getSlideProjectButtonProps = () => {
    return {
      disabled: !selected,
      to: `/projects/${ slide.project.slug.current }`
    };
  };

  const getBasicProjectHeaderProps = () => {
    return {
      selected: selected,
      title: slide.project.title,
      subtitle: slide.project.client
    };
  };

  const getDefaultHeaderProps = () => {
    return {
      selected: selected,
      title: slide.title,
      subtitle: slide.subtitle
    };
  };

  const getBasicProjectMediaImageProps = () => {
    return {
      altText: slide.project.bannerAltText,
      image: slide.project.banner
    };
  };

  const getDefaultMediaImageProps = () => {
    return {
      altText: slide.altText,
      image: slide.image
    };
  };

  const getDefaultMediaVideoProps = () => {
    return {
      vimeoVideo: slide.vimeoVideo
    };
  };

  return useMemo(() => {
    let SlideButtonComponent;
    let SlideMediaComponent;
    let slideButtonProps = {};
    let slideHeaderProps = {};
    let slideMediaProps = {};

    switch (slide._type) {
      case "carouselBasicProjectSlide":
        SlideButtonComponent = SlideProjectLinkButton;
        SlideMediaComponent = Image;
        slideButtonProps = getSlideProjectButtonProps();
        slideHeaderProps = getBasicProjectHeaderProps();
        slideMediaProps = getBasicProjectMediaImageProps();
        break;
      case "carouselExternalImageSlide":
        SlideButtonComponent = SlideExternalLinkButton;
        SlideMediaComponent = Image;
        slideButtonProps = getSlideExternalButtonProps();
        slideHeaderProps = getDefaultHeaderProps();
        slideMediaProps = getDefaultMediaImageProps();
        break;
      case "carouselExternalVideoSlide":
        SlideButtonComponent = SlideExternalLinkButton;
        SlideMediaComponent = Video;
        slideButtonProps = getSlideExternalButtonProps();
        slideHeaderProps = getDefaultHeaderProps();
        slideMediaProps = getDefaultMediaVideoProps();
        break;
      case "carouselProjectImageSlide":
        SlideButtonComponent = SlideProjectLinkButton;
        SlideMediaComponent = Image;
        slideButtonProps = getSlideProjectButtonProps();
        slideHeaderProps = getDefaultHeaderProps();
        slideMediaProps = getDefaultMediaImageProps();
        break;
      case "carouselProjectVideoSlide":
        SlideButtonComponent = SlideProjectLinkButton;
        SlideMediaComponent = Video;
        slideButtonProps = getSlideProjectButtonProps();
        slideHeaderProps = getDefaultHeaderProps();
        slideMediaProps = getDefaultMediaVideoProps();
        break;
      default:
        console.error(
          `Slide type '${ slide._type }' not supported.`
        );
        return null;
    }

    return (
      <StyledSlide>
        <SlideButtonComponent {...slideButtonProps}>
          <SlideMediaComponent { ...slideMediaProps }/>
          <Header {...slideHeaderProps}/>
        </SlideButtonComponent>
      </StyledSlide>
    );
  }, [
    selected,
    slide
  ]);
};

Slide.propTypes = {
  selected: PropTypes.bool.isRequired,
  slide: PropTypes.object.isRequired
};

export default Slide;
