import classNames from "classnames";
import styled from "styled-components";

const Home = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "home")
  };
})`
  ${({ theme: { space } }) => `
    display: flex;
    flex: 1;
    padding: ${ space.xxSmall } 0 ${ space.xxSmall } ${ space.xxSmall };

    position: relative;
  `};
`;

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
