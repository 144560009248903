import classNames from "classnames";
import styled from "styled-components";

import SlideButton from "./SlideButton";

const SlideExternalLinkButton = styled(SlideButton).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "home__carousel__slide-external-link-button"
    )
  };
})``;

SlideExternalLinkButton.propTypes = {};

SlideExternalLinkButton.defaultProps = {};

export default SlideExternalLinkButton;
