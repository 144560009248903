import React from "react";

import Carousel from "./components/Carousel";
import StyledHome from "./views/Home";

const Home = props => {
  const { data: { homePage: { carouselSlides } } } = props;

  return (
    <StyledHome>
      <Carousel
        slides={carouselSlides}
      />
    </StyledHome>
  );
};

export default Home;
