import React from "react";
import PropTypes from "prop-types";

import OpenSymbol from "./components/OpenSymbol";
import StyledHeader from "./views/Header";
import Subtitle from "./views/Subtitle";
import TextsWrapper from "./views/TextsWrapper";
import Title from "./views/Title";

const Header = props => {
  const { selected, title, subtitle } = props;

  return (
    <StyledHeader
      open={selected}
      transitionMs={Header.TRANSITION_MS}
    >
      <TextsWrapper>
        {
          subtitle && (
            <Subtitle>
              { subtitle || "" }
            </Subtitle>
          )
        }
        {
          title && (
            <Title>
              { title }
            </Title>
          )
        }
      </TextsWrapper>
      <OpenSymbol selected={selected} transitionMs={Header.TRANSITION_MS}/>
    </StyledHeader>
  );
};

Header.TRANSITION_MS = 320;

Header.propTypes = {
  title: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  subtitle: PropTypes.string
};

export default Header;
