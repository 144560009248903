import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const TRANSITION_NAME = `home__carousel__slide-header`;

const StyledHeader = styled.div.attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(
        className,
        "home__carousel__slide-header"
      ),
      tabIndex: "-1"
    };
  }
)`
  ${({ theme: { chrome, colors, space }, transitionMs }) => `
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    left: 0;
    // If Nowadays ever wants exclusion turned back on, find all instances of
    // this comment and uncomment the mix-blend-mode line.
    // mix-blend-mode: exclusion;
    opacity: 0.01;
    outline: none;
    padding:
      ${ space.small }
      ${ space.medium }
      ${ space.small }
      ${ space.small };
    pointer-events: none;
    position: absolute;
    right: 0;
    transition:
      opacity ${ transitionMs }ms cubic-bezier(.455, .03, .515, 1.0);
    will-change: opacity;
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;

    &.${ TRANSITION_NAME }-appear,
    &.${ TRANSITION_NAME }-enter {
      opacity: 0.01;
    };

    &.${ TRANSITION_NAME }-appear-active,
    &.${ TRANSITION_NAME }-enter-active {
      opacity: 1;
    };

    &.${ TRANSITION_NAME }-appear-done,
    &.${ TRANSITION_NAME }-enter-done {
      opacity: 1;
    };

    &.${ TRANSITION_NAME }-exit {
      opacity: 1;
    };

    // Careful with these && declarations. These will override mobile settings
    // and other settings that follow here.
    && {
      &.${ TRANSITION_NAME }-exit-active {
        opacity: 0.01;
      };

      &.${ TRANSITION_NAME }-exit-done {
        opacity: 0.01;
      };
    }
  `}

  ${media.sm`
    ${ ({ theme: { space } }) => `
      padding:
        ${ space.xLarge }
        ${ space.xxLarge }
        ${ space.large }
        ${ space.large };
    `}
  `}
`;

StyledHeader.propTypes = {};

StyledHeader.defaultProps = {};

const Header = props => {
  const { children, open, transitionMs, ...passdownProps } = props;

  return (
    <CSSTransition
      appear
      classNames={{
        appear: `${ TRANSITION_NAME }-appear`,
        appearActive:`${ TRANSITION_NAME }-appear-active`,
        // Keep this. Prevents `react-transition-group` from adding
        // `undefined` to class name list.
        appearDone: `${ TRANSITION_NAME }-appear-done`,
        enter: `${ TRANSITION_NAME }-enter`,
        enterActive: `${ TRANSITION_NAME }-enter-active`,
        enterDone: `${ TRANSITION_NAME }-enter-done`,
        exit: `${ TRANSITION_NAME }-exit`,
        exitActive: `${ TRANSITION_NAME }-exit-active`,
        exitDone: `${ TRANSITION_NAME }-exit-done`,
      }}
      in={open}
      timeout={{
        appear: transitionMs,
        enter: transitionMs,
        exit: transitionMs
      }}
    >
      <StyledHeader
        transitionMs={transitionMs}
        {...passdownProps}
      >
        { children }
      </StyledHeader>
    </CSSTransition>
  );
};

Header.propTypes = {
  open: PropTypes.bool.isRequired,
  transitionMs: PropTypes.number.isRequired
};

Header.defaultProps = {};

export default Header;
