import classNames from "classnames";
import styled from "styled-components";

const CarouselInnerWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "home__carousel-inner-wrapper")
  };
})`
  ${() => `
    bottom: 0;
    display: flex;
    flex: 1;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateZ(0);
  `};
`;

CarouselInnerWrapper.propTypes = {};

CarouselInnerWrapper.defaultProps = {};

export default CarouselInnerWrapper;
