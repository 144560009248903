import React from "react";
import PropTypes from "prop-types";

import StyledOpenSymbol from "./views/OpenSymbol";
import OpenSymbolWrapper from "./views/OpenSymbolWrapper";

const OpenSymbol = props => {
  const { selected, transitionMs } = props;

  return (
    <OpenSymbolWrapper>
      <StyledOpenSymbol selected={selected} transitionMs={transitionMs}/>
    </OpenSymbolWrapper>
  );
};

OpenSymbol.propTypes = {
  selected: PropTypes.bool.isRequired,
  transitionMs: PropTypes.number.isRequired
};

export default OpenSymbol;
