import React from "react";
import PropTypes from "prop-types";

import StyledNextSlideButtonPseudoButton from
  "./views/NextSlideButtonPseudoButton";

const NextSlideButtonPseudoButton = props => {
  const { displayed, onClick } = props;

  return (
    <StyledNextSlideButtonPseudoButton displayed={displayed} onClick={onClick}/>
  );
};

NextSlideButtonPseudoButton.propTypes = {
  displayed: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default NextSlideButtonPseudoButton;
