import React from "react";
import { graphql } from "gatsby";

import HomePageComponent from "@nowadays/Pages/Home";

const Home = props => {
  const { data } = props;

  return (
    <HomePageComponent data={data}/>
  );
};

export const query = graphql`
  query HomePage {
    homePage: sanityHomePage {
      ...fieldsOnHomePage
    }
  }
`;

export default Home;
