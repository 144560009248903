import classNames from "classnames";
import styled from "styled-components";

const CarouselOuterWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "home__carousel-inner-wrapper")
  };
})`
  ${({ theme: { colors } }) => `
    flex: 1;
    isolation: isolate;
    position: relative;
    transform: translateZ(0);
  `};
`;

CarouselOuterWrapper.propTypes = {};

CarouselOuterWrapper.defaultProps = {};

export default CarouselOuterWrapper;
