import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";

const SlideButton = styled.a.attrs(props => {
  const {
    className,
    disabled
  } = props;

  return {
    "aria-disabled": disabled,
    "aria-label": "Go to Slide Link",
    className: classNames(className, "home__carousel__slide-button", {
      "home__carousel__slide-button--is-disabled": disabled
    })
  };
})`

  ${({ theme: { colors } }) => `
    color: ${ colors.white };
    cursor: pointer;
    height: 100%;
    text-align: left;
    text-decoration: none;
    width: 100%;
    will-change: transform;

    &.home__carousel__slide-button--is-disabled {
      cursor: default;
      pointer-events: none;
    }

    &:focus {
      // TODO
    }
  `}
`;

SlideButton.propTypes = {
  disabled: PropTypes.bool.isRequired
};

SlideButton.defaultProps = {};

export default SlideButton;
