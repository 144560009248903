import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

import { XXSmallText } from "@nowadays/UI/Typography";

const Subtitle = styled(XXSmallText).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "home__carousel__slide-header__subtitle")
  };
})`
  ${({ theme: { space } }) => `
    margin-bottom: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}

  ${media.sm`
    ${({ theme: { space } }) => `
      margin-bottom: ${ space.xxxSmall };
      overflow: visible;
      text-overflow: clip;
      white-space: normal;
    `}
  `}
`;

Subtitle.propTypes = {};

Subtitle.defaultProps = {
  expanded: true,
  spacing: "small",
  uppercase: true
};

export default Subtitle;
