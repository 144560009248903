import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const OpenSymboleWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "home__carousel__slide-header__open-symbole-wrapper"
    )
  };
})`
  ${() => `
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}

  ${media.sm`
    ${ ({ theme: { lineHeights, space } }) => `
      && {
        justify-content: flex-end;
        margin-bottom: calc((${ lineHeights.xxLarge } / 2) - (${ space.medium } / 2));
      }
    `}
  `}
`;

OpenSymboleWrapper.propTypes = {};

OpenSymboleWrapper.defaultProps = {};

export default OpenSymboleWrapper;
