import classNames from "classnames";
import PropTypes from "prop-types";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

import { Plus } from "@nowadays/UI/Icon";

const OpenSymbol = styled(Plus).attrs(props => {
  const {
    selected,
    className
  } = props;

  return {
    className: classNames(
      className,
      "home__carousel__slide-header__open-symbol", {
        "home__carousel__slide-header__open-symbol--is-selected": selected
      }
    )
  };
})`
  ${ ({ theme: { colors, space } }) => `
    display: none;
    fill: ${ colors.white };
    height: ${ space.medium };
  `}

  ${media.sm`
    ${ ({ transitionMs }) => `
      && {
        display: block;
        opacity: 0;
        transition:
          opacity ${ transitionMs / 8 }ms cubic-bezier(.455, .03, .515, 1.0);
        will-change: opacity, transform;
        -webkit-backface-visibility: hidden;
        -webkit-transform-style: preserve-3d;

        &.home__carousel__slide-header__open-symbol--is-selected {
          opacity: 1;
          transition:
            opacity ${ transitionMs }ms cubic-bezier(.455, .03, .515, 1.0);
          transition-delay: ${ transitionMs }ms;
        }
      }
    `}
  `}
`;

OpenSymbol.propTypes = {
  selected: PropTypes.bool.isRequired,
  transitionMs: PropTypes.number.isRequired
};

OpenSymbol.defaultProps = {
  selected: false
};

export default OpenSymbol;
