import React from "react";
import PropTypes from "prop-types";

import StyledNextSlideButton from "./views/NextSlideButton";

const NextSlideButton = props => {
  const { onClick, settled } = props;

  return (
    <StyledNextSlideButton onClick={onClick} settled={settled}/>
  );
};

NextSlideButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  settled: PropTypes.bool.isRequired
};

export default NextSlideButton;
